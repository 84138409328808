import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { SecondaryHero } from '@latitude/hero';
import { ImportantInformation } from '@latitude/important-information';
import { Heading6, Heading2 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { COLOR, MARGIN } from '../../utils/constants';
import { Box } from '../../components/Box/Box';
import {
  PlanTypes,
  PLAN_TYPE_INSTALMENT_IF,
  PLAN_TYPE_INTEREST_FREE,
  PLAN_TYPE_INTEREST_FREE_DEFFERRED
} from '../../components/InterestFreePlans';
import Layout from '../../components/layout';

const TermsAndConditions = props => {
  PLAN_TYPE_INSTALMENT_IF.href = '#instalmentif';
  PLAN_TYPE_INSTALMENT_IF.linkText = 'View terms and conditions';
  PLAN_TYPE_INSTALMENT_IF.onClick = scrollHandler;
  PLAN_TYPE_INTEREST_FREE.href = '#interestfree';
  PLAN_TYPE_INTEREST_FREE.linkText = 'View terms and conditions';
  PLAN_TYPE_INTEREST_FREE.onClick = scrollHandler;
  PLAN_TYPE_INTEREST_FREE_DEFFERRED.href = '#defferred';
  PLAN_TYPE_INTEREST_FREE_DEFFERRED.linkText = 'View terms and conditions';
  PLAN_TYPE_INTEREST_FREE_DEFFERRED.onClick = scrollHandler;
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>Interest Free terms and conditions | Gem by Latitude</title>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/credit-cards/terms-and-conditions"
          />
          <meta
            name="description"
            content="Get in touch to find out more about our interest free payment plans. T&amp;Cs &amp; fees apply."
          />
        </Helmet>

        <SecondaryHero heading={<>Interest Free terms & conditions</>} />
        <PlanTypes
          heading="Our Interest Free Payment Plans explained"
          types={[
            PLAN_TYPE_INTEREST_FREE,
            PLAN_TYPE_INSTALMENT_IF,
            PLAN_TYPE_INTEREST_FREE_DEFFERRED
          ]}
        />
        <HorizontalRule />
        <Anchor id="interestfree" />
        <InterestFree />
        <HorizontalRule />
        <Anchor id="instalmentif" />
        <InstalmentIF />
        <HorizontalRule />
        <InstalmentIF2 />
        <HorizontalRule />
        <Anchor id="defferred" />
        <InterestFreeDefferred />
        <ImportantInformation
          id="important-information"
          css={`
            h6 {
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
              font-family: Montserrat, sans-serif;
              margin: 14px 0;
              color: #42484d;
            }
            ul,
            ol {
              padding-left: 30px;
            }
          `}
          data={{
            content: {
              importantInformationSectionOne: [
                '<sup>*</sup>&nbsp;Long term interest free offers may vary. Available at participating retailers to approved customers only. Credit and lending criteria and fees, including a Gem Visa $55 establishment fee and annual fees ($65 Gem Visa (charged $32.50 half yearly)/$65 Gem CreditLine (charged $32.50 half yearly)). Prevailing interest rate (currently Gem Visa 29.49% p.a./Gem CreditLine 29.95% p.a.) applies after any interest free term ends. Paying only the minimum monthly repayment of 3% of the outstanding balance or $20 (whichever is more), will not be sufficient to repay the purchase amount(s) within the promotional period. Amount payable will be shown on your monthly statement. For cash advances, an interest rate of 29.95% p.a. and fees of ($2 Gem Visa/$6 Gem CreditLine) applies. Further information on rates and fees can be found at gemfinance.co.nz. Credit provided by Latitude Financial Services Limited.'
              ],
              importantInformationSectionTwo: [
                '<h6>SECURITY</h6><p>If there\'s a fraudulent transaction on your Gem Visa, you will not be held liable if you:</p><ul><li>have not contributed to the loss</li><li>notified us promptly of the theft, loss or misuse</li><li>have followed the <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-nz/cou.pdf">Gem Visa Conditions of Use</a></li></ul>'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        />
      </main>
    </Layout>
  );
};

const InterestFree = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy>
    <Heading6>INTEREST FREE</Heading6>
    <Text>
      Minimum and maximum purchase amounts may apply. Minimum monthly repayments
      required. Paying only the minimum monthly repayment of 3% of the
      outstanding monthly balance or $20 (whichever is more), will not be
      sufficient to repay the purchase amount(s) within the promotional period.
      Amount payable will be shown on your monthly statement.
      <br />
      <br />
      Credit and lending criteria and fees, including a Gem Visa $55
      establishment fee and annual fees ($65 Gem Visa (charged $32.50 half
      yearly)/$65 Gem CreditLine (charged $32.50 half yearly)). Prevailing
      interest rate (currently Gem Visa 29.49% p.a./Gem CreditLine 29.95% p.a.)
      applies after any interest free term ends. For cash advances, an interest
      rate of 29.95% p.a. and fees of ($2 Gem Visa/$6 Gem CreditLine) applies.
      Further information on rates and fees can be found at gemfinance.co.nz.
      Available on participating Gem Visa or Gem CreditLine cards only. Credit
      provided by Latitude Financial Services Limited.
    </Text>
  </Box.Section>
);

const InstalmentIF = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy>
    <Heading6>INSTALMENT INTEREST FREE</Heading6>
    <Text>
      Approved customers only. Stated plan term of Instalment Interest Free with
      Gem Visa / Gem CreditLine cards as an Instalment Plan Advance / CreditLine
      Select advance. Amount payable will be shown on your monthly statement.
      Minimum and maximum purchase amounts may apply.
      <br />
      <br />
      Credit and lending criteria and fees, including a Gem Visa $55
      establishment fee and annual fees ($65 Gem Visa (charged $32.50 half
      yearly)/$65 Gem CreditLine (charged $32.50 half yearly)). Prevailing
      interest rate (currently Gem Visa 29.49% p.a./Gem CreditLine 29.95% p.a.)
      applies after any interest free term ends. For cash advances, an interest
      rate of 29.95% p.a. and fees of ($2 Gem Visa/$6 Gem CreditLine) applies.
      Further information on rates and fees can be found at gemfinance.co.nz.
      Available on participating Gem Visa or Gem CreditLine cards only. Credit
      provided by Latitude Financial Services Limited.
    </Text>
  </Box.Section>
);

const InstalmentIF2 = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy>
    <Heading6>
      INSTALMENT INTEREST FREE PLAN &ndash; PLAN TERM OF LESS THAN 36 MONTHS
      ONLY
    </Heading6>
    <Text>
      Approved customers only. Plan terms of less than 36 months of Instalment
      Interest Free with Gem Visa or Gem CreditLine as an Instalment Plan
      Advance/CreditLine Select Advance. Amount payable will be shown on your
      monthly statement. Minimum and maximum purchase amounts may apply. As the
      Instalment Interest Free plan is for a term less than 36 months, the
      minimum monthly payment under this plan is greater than the usual interest
      free repayment for the same term. Please refer to your Gem Visa or Gem
      CreditLine Conditions of Use in regard to the payment allocation for this
      plan.
      <br />
      <br />
      If you don&rsquo;t make your minimum monthly payment as provided in your
      statement, for two consecutive months, you agree that all Instalment
      Interest Free plans with a term less than 36 months will change to an
      Interest Free plan for the remainder of the original plan&rsquo;s term.
      <br />
      <br />
      Credit and lending criteria and fees, including a Gem Visa $55
      establishment fee and annual fees ($65 Gem Visa (charged $32.50 half
      yearly)/$65 Gem CreditLine (charged $32.50 half yearly)). Prevailing
      interest rate (currently Gem Visa 29.49% p.a./Gem CreditLine 29.95% p.a.)
      applies after any interest free term ends. For cash advances, an interest
      rate of 29.95% p.a. and fees of ($2 Gem Visa/$6 Gem CreditLine) applies.
      Further information on rates and fees can be found at gemfinance.co.nz.
      Credit provided by Latitude Financial Services Limited.
    </Text>
  </Box.Section>
);

const InterestFreeDefferred = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy>
    <Heading6>INTEREST FREE DEFERRED</Heading6>
    <Text>
      Interest free with no repayments during the stated period. Minimum and
      maximum purchase amounts may apply. Repayment for your purchase will be
      deferred for the stated period. During this period, no interest will
      accrue and no monthly repayments need to be made. If you do not pay the
      outstanding balance in full before the end of the deferred payment period,
      the amount payable will accrue interest and will be included in your
      monthly statement. Paying only the minimum monthly repayment of 3% of the
      outstanding monthly balance or $20, whichever is more, will not be
      sufficient to repay the purchase amount(s) within the interest free
      period.
      <br />
      <br />
      Credit and lending criteria and fees, including a Gem Visa $55
      establishment fee and annual fees ($65 Gem Visa (charged $32.50 half
      yearly)/$65 Gem CreditLine (charged $32.50 half yearly)). Prevailing
      interest rate (currently Gem Visa 29.49% p.a./Gem CreditLine 29.95% p.a.)
      applies after any interest free term ends. For cash advances, an interest
      rate of 29.95% p.a. and fees of ($2 Gem Visa/$6 Gem CreditLine) applies.
      Further information on rates and fees can be found at gemfinance.co.nz.
      Credit provided by Latitude Financial Services Limited.
    </Text>
  </Box.Section>
);

const Anchor = styled.div`
  top: -20px;
  display: block;
  position: relative;
`;

/**
 * Handle smooth scroll functionality to given element/selector
 */

export const handleSmoothScroll = (selector: string, offset: number = 0) => {
  if (typeof document !== 'undefined') {
    const anchorEl = document.querySelector(selector);

    if (anchorEl) {
      window.scrollTo({
        top: anchorEl.offsetTop - offset,
        behavior: 'smooth'
      });
    }
  }
};

const scrollHandler = event => {
  event.preventDefault();
  handleSmoothScroll(event.target.hash, 80);
};

export default TermsAndConditions;
